<template>
  <FrInputContainer :plain="plain" :padded="isPadded" class="flex relative content-center items-center">
    <slot name="start"></slot>
    <div
      v-if="slots.icon"
      class="icon-slot flex items-center pr-1 absolute left-[10px] size-[20px]"
      @click="inputRef.focus()">
      <slot name="icon"></slot>
    </div>
    <input
      ref="inputRef"
      :type="inputType"
      v-model.trim="val"
      :class="classes"
      :disabled="disabled"
      :readonly="readonly"
      :name="name"
      :id="id"
      :autocomplete="autocomplete === false ? 'off' : true"
      class="fr-input block w-full sm:text-sm border shadow-sm focus:ring-0 focus:ring-offset-0 appearance-none"
      @focus="focus"
      @blur="blur"
      @keyup="keyup"
      @keydown="keydown"
      @input="change"
      @paste="change"
      :placeholder="placeholder" />
    <slot name="end"></slot>
  </FrInputContainer>
</template>
<script setup>
import { computed, onMounted, ref, watch, useSlots } from 'vue'
import debouncedRef from '@/lib/debouncedRef'

import FrInputContainer from '@ui/FrForm/FrInputContainer.vue'

const emit = defineEmits(['update:modelValue', 'key-enter', 'enter', 'change', 'focus', 'blur', 'keyup', 'keydown'])
const props = defineProps({
    modelValue: {
      type: [String, Number, Object, Array]
    },
    placeholder: {
      type: String,
      default() {
        return null
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    plain: {
      type: Boolean,
      default() {
        return false
      }
    },
    padded: {
      type: Boolean,
      default() {
        return true
      }
    },
    inputPaddingSm: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default() {
        return 'white'
      }
    },
    name: {
      type: String,
      default() {
        return null
      }
    },
    id: {
      type: String,
      default: null
    },
    email: {
      type: Boolean,
      default: false
    },
    password: {
      type: Boolean,
      default: false
    },
    decimal: {
      type: Boolean,
      default: false
    },
    integer: {
      type: Boolean,
      default: false
    },
    formatter: {
      type: Function ,
      default: null
    },
    unpadded: {
      type: Boolean,
      default: false
    },
    roundedl: {
      type: Boolean,
      default: false
    },
    selectFocus: {
      type: Boolean,
      default: false
    },
    focusauto: {
      type: Boolean,
      default: false
    },
    float: {
      type: Boolean,
      default: false
    },
    alpha: {
      type: Boolean,
      default: false
    },
    debounce: {
      type: [Boolean, Number],
      default: false
    },
    autocomplete: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    dataType: {
      type: String,
      default: 'text'
    }
  })

const inputRef = ref(null)
const slots = useSlots()

let val

if (props.debounce) {
  val = debouncedRef(props.modelValue, props.debounce === true ? 500 : props.debounce)
  watch(val, newVal => {

    let cleanedVal
    if (val) {
      if (props.dataType === 'decimal') {
        cleanedVal = cleanValDecimal(newVal)
        if (newVal != cleanedVal) {
          if (isNaN(cleanedVal)) {
            cleanedVal = ''
          }

          newVal = cleanedVal
          val.value = cleanedVal
        }
      } else if (props.dataType === 'integer') {
        cleanedVal = String(parseFloat(val))
        if (isNaN(cleanedVal)) {
          cleanedVal = ''
        }
        if (newVal != cleanedVal) {
          newVal = cleanedVal
          val.value = cleanedVal
        }
      }
    }

    emit('update:modelValue', newVal)
    emit('change')
  })
} else {
  val = computed({
    get: () => props.modelValue,
    set: (vals) => {
      emit('update:modelValue', vals)
      emit('change')
    }
  })
}

if (props.focusauto) {
  onMounted(() => {
    setTimeout(() => {
      inputRef.value.focus()
    }, 0)
  })
}

const cleanValDecimal = (val) => {
  const parts = val.split(/[/.,]/)

  const num = parts[0]
  const matches = val.match(/[\.,]/)
  const hasComma = matches && matches[0] === ','
  const hasDecimal = matches && matches[0] === '.'

  let decimal = parts[1] ? parts[1] : null

  let commaStr = ''
  if (hasComma === true) {
    commaStr = ','
  } else if (hasDecimal) {
    commaStr = '.'
  }

  let decimalStr = ''

  if (decimal) {
    const zeroMatches = decimal.match(/^(0)+/)

    decimalStr = String(parseFloat(decimal))

    if (zeroMatches) {
      decimalStr = zeroMatches[0] + decimalStr
    }
  }

  const numStr = String(parseFloat(num))

  return numStr + commaStr + decimalStr
}

const keyup = (ev) => {
  if (ev.key === 'Enter') {
    emit('key-enter', ev);
    emit('enter', ev)
  }

  emit('keyup')
}

const isInteger = (val) => {
  return val.match(/[0-9]/) ? true : false
}

const isDecimal = (val) => {
  return val.match(/[0-9\.\,]/) ? true : false
}

const isAlpha = (val) => {
  return val.match(/[A-Za-z]/) ? true : false
}

const isDecimalType = props.decimal || props.type === 'decimal'

const isIntegerType = props.integer || props.type === 'integer'

const keydown = (ev) => {
  if (['Enter', 'Backspace'].includes(ev.key)) {
    emit('keydown', ev)
    return true
  }

  if (ev.metaKey !== true) {
    if (props.float || isIntegerType) {
      if (!isInteger(ev.key)) {
        ev.stopPropagation()
        ev.preventDefault()
        return false
      }
    }

    if (isDecimalType) {
      if (!isDecimal(ev.key)) {
        ev.stopPropagation()
        ev.preventDefault()
        return false
      }
    }

    if (props.alpha) {
      if (!isAlpha(ev.key)) {
        ev.stopPropagation()
        ev.preventDefault()
        return false
      }
    }
  }

  emit('keydown', ev)
  return true
}

const blur = (ev) => {
  emit('blur')
}

const change = () => {
  if (props.formatter) {
    emit('update:modelValue', props.formatter(val.value))
  }
}

const classes = computed (() => {
  let str = ''
  const arr = []
  if (props.color === 'white') {
    arr.push('focus:border-lightblue')
    arr.push('border-gray-lighter')
    arr.push('bg-white')
  } else if (props.color === 'transparent') {
    arr.push('focus:border-white/80')
    arr.push('border-white/40')
    arr.push('bg-transparent')
  }

  if (props.inputPaddingSm) {
    arr.push('p-1')
  } else {
    arr.push('p-2')
  }

  if (props.roundedl) {
    arr.push('rounded-l-md')
  } else {
    arr.push('rounded-md')
  }

  if (slots.icon) {
    arr.push('pl-8')
  }

  return arr.join(' ');
})

const inputType = computed(() => {
  if (props.email) {
    return 'email'
  }

  if (props.password) {
    return 'password'
  }

  if (props.float || isIntegerType || isDecimalType) {
    return 'number'
  }

  return props.type
})

const isPadded = computed(() => {
  if (props.unpadded === true) {
    return false
  }

  return props.padded
})

const focus = (ev) => {
  if (props.selectFocus) {
    ev.target.select()
  }

  emit('focus')
}

defineExpose({ inputRef })
</script>
<style scoped>
:slotted(.icon-slot svg) {
  @apply h-[16px] w-[16px];
}
</style>
